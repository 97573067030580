@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$btn-padding-y:                         .5rem;
$btn-padding-x:                         2rem;
$btn-border-radius:                     0rem;
$btn-border-radius-sm:                  $btn-border-radius;
$btn-border-radius-lg:                  $btn-border-radius;

$component-default-margin:              1rem;

$header-height:                         6.0625rem;
$header-height-sm:                      6.9375rem;
$header-height-md:                      7.75rem;
$header-margin-bottom:                  3rem;
$header-margin-bottom-sm:               5rem;
$header-margin-bottom-md:               6rem;
