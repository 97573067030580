.shop_products_container {
  position: relative;
  /* display: flex;
  flex-direction: row; 
  margin-right: 5%;
  margin-left: 5%; */
}

.shop_products_navigation {
  max-width: 150px;
}

.shop_products_scrollpage {
  margin-bottom: 10px;
  width: 100%;
}

.navigation_categories_head {
  max-width: 150px;
  width: 160%;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  justify-content: left;
  border-radius: 2px;
  background-color: #2DB997;
  color: white;
  }

  .navigation_categories_container {
    max-width: 150px;
  }

  .navigation_categories {
    font-weight: 90;
    color: black;
    font-size: 14px;
    line-height: 3;
  }

  .container {
    margin: 0 auto;
  }

  .product_tile_container { 
    min-width: 16rem;
  }

  .col {
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;

  }

  .product_tile {
    position: relative;
    max-width: 16rem;
    width: 100%;
    min-width: 16rem;
    height: auto;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
    margin: 0;
    transform: translateX(-10px);
  }


  .product_tile_image {
    width: 16rem;
    max-height: 150px;
    object-fit: cover;
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    font-size:  12px;
    /* white-Space: nowrap;  */
    padding-bottom: 20px;
    overflow: hidden;
  }

  .tile_btn_entfernung {
    position: absolute; 
    right: 0;
    margin: 0 auto;
    margin-right: 10px;
    background-color: #2DB997;
    width: 85%;
    max-width: 70px;
    height: 30px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 12px;
  }
/* style={{fontSize: "12px", whiteSpace:"nowrap", overflow:"hidden", textOverflow: "ellipsis" }} */

.zipcodefilter {
  /* width: 100%; */
  /* max-width: 150px; */
  margin: 0 auto;
}
