/* ===== Start: search bar =====*/
.search-link {
  display: none;
}

.search-bar {
  position: relative;
  border: 0.5px solid #e2e2e25e;
  margin: 0 auto;
  margin-top: 50px;
  width: 95%;
  max-width: 380px;
  height: 48px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(230, 230, 230, 0.85);
  overflow: hidden;
}

.search-bar input {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 40px;
  left: 152px;
  margin: 4px 0;
  border: 0;
  border-radius: 0;
  outline: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 100;
}

/* ===== End: search bar =====*/
/* ===== Start: product tempaltes =====*/
#product-templates {
  flex-flow: wrap;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin-top: 40px;
  margin-bottom: 60px;
}

figure.product-template {
  font-family: 'Thasadith', sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 15px;
  min-width: 410px;
  /* max-width: 510px; */
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgb(244, 244, 244);
  border-radius: 7px;
}

figure.product-template:hover {
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 0px 19px 0 rgb(236, 236, 236);
}

figure.product-template * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

figure.product-template img {
  width: 100%;
  vertical-align: top;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 30px;
  border-radius: 40px;
}

figure.product-template figcaption {
  padding: 0 45px 25px 45px;
  position: relative;
}

figure.product-template h3,
figure.product-template p {
  margin: 0;
  padding: 0;
}

figure.product-template h3 {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600;
  color: #333333;
  text-transform: uppercase;
  font-size: 21px;
}

figure.product-template p {
  font-size: 0.8em;
  margin-bottom: 20px;
  line-height: 1.9em;
}

figure.product-template .product-detail-button {
  background-color: #ffffff;
  color: #7a7a7a;
  border: medium none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 10px 0;
  border: 1px solid black;
  width: 100%
}

figure.product-template .product-detail-button:hover {
  color: #000000;
}

figure.product-template .product-usage-button {
  border: medium none;
  padding: 10px 40px;
  background-color: rgb(152, 168, 179);
  font-weight: 800;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 10px 0;
  width: 100%
}

figure.product-template a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

figure.product-template .product-usage-button:hover {
  background-color: rgb(77, 171, 109);
}

.product-delete-button-section {
  margin-top: 20px;
  border-top: 0.5px solid #d8d8d8;
}

figure.product-template .product-delete-button {
  border: medium none;
  padding: 10px 10px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.5em;
  margin: 10px 0;
  text-align: center;
  width: 80px;
  border-bottom: 1px solid black;
}

figure.product-template .product-delete-button:hover {
  background-color: rgb(239, 88, 88);
  border: none;
  color: #ffffff;
}

@media all and (max-width: 880px) {
  figure.product-template {
    min-width: 320px;
  }
}

@media all and (max-width: 840px) {
  figure.product-template {
    min-width: 620px;
  }
}

@media all and (max-width: 636px) {
  figure.product-template {
    margin: 5px;
    min-width: 220px;
  }
}

/* ===== End: product tempaltes =====*/
/* ===== Start: fallback =====*/
.fallback-section {
  text-align: center;
  background-color: #f7f7f7;
  width: 300px;
  padding: 50px;
  margin: 0 auto;
  border-radius: 7px;
}

.fallback-section h3 {
  font-size: 20px;
}

/* ===== Start: fallback =====*/
/* ===== Start: template selection =====*/
.template-selection-wrapper {
  flex-flow: wrap;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin-top: 40px;
  margin-bottom: 60px;
}

.template-category {
  margin: 20px;
  color: #fff;
  position: relative;
  overflow: hidden;
  padding: 30px;
  min-width: 210px;
  max-width: 400px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  font-size: 16px;
  /* box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgb(244, 244, 244); */
}

.template-category h1 {
  font-size: 1em;
  text-align: left;
  color: #000000;
}

.template-category p {
  margin: 30px 0;
}

.template-category button {
  border: medium none;
  font-weight: 700;
  padding: 5px;
  color: #000000;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  border-bottom: 1px solid black;
  background-color: #ffffff;
}

.template-category button:hover {
  border: medium none;
  font-weight: 700;
  padding: 5px;
  color: green;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  border-bottom: 1px solid green;
}

/* ===== End: template selection =====*/
/* ===== Start: template detail =====*/
.product-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: rgba(212, 212, 212, 0.9);
  transition: all 2s;
  -webkit-transition: all 2s;
  /* Safari 3.1 to 6.0 */
}

.product-details-sidebar {
  position: fixed;
  width: 500px;
  height: 100%;
  padding: 40px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  z-index: 10;
  cursor: pointer;
  box-shadow: 30px 5px 50px #636363;
  overflow: scroll;
}

@media (max-width: 576px) {
  .product-details-sidebar {
    width: 100%;
  }
}

.detail-close-section {
  font-size: 14px;
  text-align: center;
}

.detail-close-text {
  vertical-align: middle;
  height: 5%;
  width: auto;
}

.produkt-information-schliessen-icon {
  height: auto;
  width: 4%;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: 3px;
}

.details-image-section {
  margin-top: 25px;
}

.details-image {
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.details-main-information {
  margin-top: 20px;
}

.details-title {
  color: black;
  font-size: 20px;
  text-align: left;
}

.details-description {
  font-size: 12px;
  line-height: 20px;
}

.details-undertitle {
  color: black;
  font-size: 15px;
  text-align: left;
  text-decoration: underline;
}

.detail-view-price {
  color: #578D63;
  font-weight: 800;
}

.detail-buttons {
  margin-top: 40px;
  border-top: 1px solid #d8d8d8;
  padding-top: 20px;
}

.detail-buttons button {
  font-size: 12px;
}

.detail-buttons .product-usage-button {
  border: medium none;
  padding: 10px 40px;
  /* background-color: rgb(152, 168, 179); */
  font-weight: 500;
  color: #000000;
  border: 1px solid black;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.5em;
  margin: 10px 0;
  width: 100%;
  background-color: #ffffff;
}

.detail-buttons .product-publish-button {
  border: medium none;
  padding: 10px 40px;
  background-color: #39ab71;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.5em;
  margin: 10px 0;
  width: 100%;
}

.details-text {
  font-size: 12px;
}

.delete-query-section {
  right: 50px;
  bottom: 50px;
  cursor: pointer;
  overflow: scroll;
  z-index: 300;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 915px) {
  .delete-query-section {
    right: -150px;
  }
}

.delete-content-section {
  margin: 70px;
  text-align: center;
  font-size: 15px;
}

.delete-content-section .button-section {
  margin-top: 60px;
}

.button-section .button {
  margin: 10px;
  background-color: #ffffff;
  color: #7a7a7a;
  border: medium none;
  padding: 10px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  border: 1px solid black;
}

.button-section .delete-button {
  background-color: #dc3545;
  border: none;
  color: #ffffff
}
