body {
    font-family: $text-fontfamily;
    font-size: 1rem;
    font-weight: 300;
    hyphens: auto;
}

@include media-breakpoint-up(sm) {
    body {
        font-size: 1.2rem;
    }
}

@import "headings";
