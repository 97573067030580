// corporate colors
$q-green:                              #2DB997;
$q-white:                              #FFFFFF;
$q-gray:                               #575856;
$q-darkgray:                           #1F1E1E;

$primary:                               $q-green;
$secondary:                             $q-gray;
$tertiary:                              $q-darkgray;
$white:                                 #FFFFFF;
$black:                                 #000000;
$gray:                                  #5f5f5f;
$gray-light:                            #f7f7f9;
$gray-semilight:                        #efefef;

$body-color:                            $q-darkgray;
$body-bg:                               $white;

$header-background:                     $black;
$header-text-color:                     #FFFFFF;
$header-text-hover-color:               #FF0090;

$state-danger-text:                     #a94442;

$headline-color:                        $primary;
$headline-background:                   $white;

$link-color:                            $primary;
$link-decoration:                       none;
$link-hover-color:                      $tertiary;
$link-hover-decoration:                 underline;

$footer-background:                     $black;
$footer-background-gradient:            $primary;
