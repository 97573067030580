@media (min-width: 768px) {
  .hide--on-sm {
    display: none;
  }
}

@media (max-width: 992px) {
  .hidden {
    display: none;
  }
}

/* xs */
@media (min-width: 30px) {
  .shoppingCart--img {
    width: 80px;
    height: 80px;
  }
  .shoppingCart--text {
    font-size: 14px;
  }

}
/* sm */
@media (min-width: 768px) {
  .profile--img {
      width: 200px;
      height: 200px;
  }

  .shoppingCart--img {
    width: 120px;
    height: 120px;
  }
}

/* md */
@media (min-width: 992px) {
  .profile--img {
      width: 250px;
      height: 250px;
  }
  .shoppingCart--img {
    width: 180px;
    height: 180px;
  }
}

/* lg */
@media (min-width: 1200px) {
  .profile--img {
      width: 350px;
      height: 350px;
  }
  .shoppingCart--img {
    width: 200px;
    height: 200px;
  }
}

/* xl */
@media (min-width: 2000px) {
  .shoppingCart--img {
    width: 200px;
    height: 200px;
  }
}



.header_jumbotron {
  margin-Right : 5%px;
  margin-Left: 5%px;
  margin-Top: 20px;
  display: flex;
  border-radius: 5px;
  object-fit: none;
  object-position: 0;
}

.header_container_pic {
  height: 150px;
  width: auto;
  width: 100%;
  object-fit: cover;
  object-position: 30% 40%; /*default value: image is centered*/
  object-position: 0 1;
  border-radius: 5px;
}

