.container_tips_jumbo1 {
  position: relative;
  margin: 0 auto;
  justify-content: center;
}


.tips_jumbo1 {
  margin: 10px;
  margin-top: 2px;
  margin-bottom: 15px;
  padding: 10px;
  /* width: 85%; */
  max-width: 200px;
  height: 180px;
  background-color: #2DB997;
  border-radius: 2px;
  box-shadow: 0 2px 10px rgba(230, 230, 230, 0.85);
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 14px;
}

.tips_jumbo1_img {
  height: 50px;
  width: auto;
}

.tips_jumbo1_txt {
  margin-top: 15px;
  font-size: 12px;
}

.home_pincode_input {
  position: relative;
  border: 0.5px solid #e2e2e25e;
  margin: 0 auto;
  margin-top: 15px;
  padding: 10px;
  width: 85%;
  max-width: 300px;
  height: 180px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(230, 230, 230, 0.85);
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-size: 14px;
}

.home_zipcode_input_container {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(230, 230, 230, 0.85);
  border-color: rgb(209, 209, 209);
  padding: 15px;
}

.home_zipcode_input_element {
  position: relative;
  margin-top: 15px;
  width: 100%;
}

.howto_container {
  position: relative;
  justify-content: left;
  margin: 0 auto;
  overflow: hidden;
}

.home_howto_container {
  position: relative;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.home_howto_container_element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  margin-top: 2px;
  margin-bottom: 15px;
  padding: 10px;
  min-width: 200px;
  max-width: 200px;
  height: 180px;
  background-color: #dddddd;
  border-radius: 2px;
  box-shadow: 0 2px 10px rgba(230, 230, 230, 0.85);
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-size: 14px;
}

.home_howto_container_element_img {
  width: auto;
  height: 90px;
}

.home_howto_container_element_txt {
  margin-top: 15px;
  left: 20%;

}


